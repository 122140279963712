<template>
  <b-tab active>
    <template #title>📝<span>Propiedades del artículo</span></template>

    <b-row>
      <b-col md="12 text-right mb-2" v-if="!product.isMainProduct && existMainProduct">
        <b-button
          size="sm"
          variant="btn btn-primary"
          class="btn-tour-skip mr-1"
          @click="copyProduct(product)"
        >
          <span class="mr-25 align-middle">📋 Copiar datos cliente principal</span>
        </b-button>
      </b-col>
    </b-row>

    <b-table
      bordered
      responsive="sm"
      :items="itemTableTotals"
      :fields="tableColumns"
      sticky-header="100"
    >
      <template #cell(qty)="data">
        <div class="text-nowrap" v-if="data.item.modelType == 'rates'">
          <span>{{ data.item.typeRate }}: </span>
          <b class="float-right">{{ data.value }}</b>
        </div>
        <span class="text-nowrap" v-else>{{ data.value }}</span>
      </template>
    </b-table>

    <ValidationObserver ref="observer">
      <b-row v-if="product.manualPrice">
        <b-col md="6">
          <ValidationProvider rules="required|min_value:1" name="Precio de venta unitario">
            <b-form-group slot-scope="{ valid, errors }" label="Precio de venta unitario">
              <b-form-input
                type="number"
                step=".01"
                v-model="product.originalSalePrice"
                :state="errors[0] ? false : (valid ? true : null)"
                placeholder="Ingrese el precio"
                @blur="replaceInput(product.originalSalePrice)"
              >
              </b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <ValidationProvider rules="required" name="Operation date" >
            <b-form-group slot-scope="{ valid, errors }" label="Fecha de operación">
              <flat-pickr
                :disabled="product.modelType == 'rates' || product.isGettingUnavaliableDates"
                v-model="product.operationdate"
                class="form-control"
                :config="product.blackoutDates"
                @input="setDateOperation(product)"
                :state="errors[0] ? false : (valid ? true : null)"
              />
            </b-form-group>
          </ValidationProvider>
          <div class="text-center" v-if="product.isGettingUnavaliableDates" >
                        <p class="text-warning">Obteniendo disponibilidad</p>
                        <b-spinner label="Loading..." variant="info" />
                    </div> 
        </b-col>
        <b-col md="3">
          <ValidationProvider rules="required" name="hora operación">
            <div slot-scope="{ valid, errors }">
              <label for="">Hora de operación</label><br>
              <vue-timepicker
                v-model="product.operationtime"
                input-width="100%"
                :state="errors[0] ? false : (valid ? true : null)"
                placeholder="Ingrese hora de operación"
                @input="setDataProduct(product)"
                :minute-interval="30"
              >
                <template v-slot:clearButton>
                  <feather-icon icon="XCircleIcon" style="color:black;"/>
                </template>
              </vue-timepicker>
              <label style="color:red;">
                {{ errors[0] }}
              </label>
            </div>
          </ValidationProvider>
        </b-col>
        <b-col md="3">
          <ValidationProvider rules="required" name="">
            <b-form-group slot-scope="{ errors }" prepend="Booking-reference">
              <label for="Booking reference">Referencia de reserva</label>
              <b-form-input
                type="text"
                placeholder="Referencia de reserva"
                v-model="product.bookingReference"
                @input="setDataProduct(product)"
              >
              </b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <!-- Beneficiarios -->
        <b-col md="3">
          <ValidationProvider rules="required" name="Nombre-beneficiarios">
            <b-form-group slot-scope="{ errors }" prepend="Nombre-beneficiarios">
              <label for="Nombre-beneficiarios">Nombre beneficiario</label>
              <b-form-input
                type="text"
                placeholder="Nombre Completo"
                v-model="product.beneficiarios.fullname"
                @input="setDataProduct(product)"
              >
              </b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="3">
          <ValidationProvider rules="email" name="Correo-Beneficiario">
            <b-form-group slot-scope="{ errors }" prepend="Correo-Beneficiario">
              <label for="Correo-Beneficiario">Correo beneficiario</label>
              <b-form-input
                type="email"
                placeholder="Correo"
                v-model="product.beneficiarios.email"
                @input="setDataProduct(product)"
              >
              </b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="3">
          <ValidationProvider rules="required" name="Telefono-Beneficiario">
            <b-form-group slot-scope="{ errors }" prepend="Telefono-Beneficiario">
              <label for="Telefono-Beneficiario">Teléfono beneficiario</label>
              <b-form-input
                type="tel"
                placeholder="Teléfono"
                v-model="product.beneficiarios.phone"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="20"
                @keypress="formatPhoneNumber"
                @input="setDataProduct(product)"
              >
              </b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="3">
          <ValidationProvider rules="required" name="Habitacion-Beneficiario">
            <b-form-group slot-scope="{ errors }" prepend="Habitacion-Beneficiario">
              <label for="Habitacion-Beneficiario">Habitación beneficiario</label>
              <b-form-input
                type="text"
                placeholder="Habitación"
                v-model="product.beneficiarios.room"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="10"
                @input="setDataProduct(product)"
              >
              </b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>

      </b-row>
      <label>(Si algún campo del beneficiario se deja en blanco, se autorellena con los datos del cliente)</label>
      <b-row>
        <b-col md="6">
          <ValidationProvider rules="max:255" name="nota interna">
            <b-form-group slot-scope="{ valid, errors }" label="Nota Interna( max: 255)">
              <b-input-group>
                <b-form-textarea
                  v-model="product.note"
                  :state="errors[0] ? false : (valid ? true : null)"
                  placeholder="Escribe la nota"
                  :maxlength="max"
                  rows="1"
                  @input="setDataProduct(product)"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-input-group>
              <div class="text-center">
                <b-badge variant="success" style="width: 60%; cursor: not-allowed;">Le quedan {{max - product.note.length}} caracteres</b-badge>
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="6">
          <ValidationProvider rules="max:255" name="notas cliente">
            <b-form-group slot-scope="{ valid, errors }" label="Nota cliente( max: 255)">
              <b-input-group>
                <b-form-textarea
                  v-model="product.clientnote"
                  :state="errors[0] ? false : (valid ? true : null)"
                  placeholder="Escribe la nota"
                  :maxlength="max"
                  rows="1"
                  @input="setDataProduct(product)"
                />
                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
              </b-input-group>
              <div class="text-center">
                <b-badge variant="success" style="width: 60%; cursor: not-allowed;">Le quedan {{max - product.clientnote.length}} caracteres</b-badge>
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-col md="12 ">
        <b-form-checkbox
          class="mt-2 mb-2 float-right"
          v-model="product.isMainProduct"
          name="checkbox-1"
          value="true"
          :disabled="!product.isMainProduct && existMainProduct"
          unchecked-value="false"
          @change="setMain(product, $event)"
        >Usar datos cliente principal
        </b-form-checkbox>
      </b-col>
    </ValidationObserver>
  </b-tab>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { toJson, currentDate } from '@/helpers/helpers'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import 'vue2-timepicker/dist/VueTimepicker.css'
import flatPickr from 'vue-flatpickr-component'
import { market } from '@/modules/shop/mixins/market'

export default {
  mixins: [market],
  created(){
    if (!this.product.operationtime) this.product.operationtime = '00:00'
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
   components: {
    VueTimepicker,
    flatPickr
  },

  data () {
    return {
      max: 255,
      fechaActual: currentDate(),
      tableColumns: [
        { key:'qty', label: 'Cantidad' },
        { key:'unitPrice', label: 'Precio venta', formatter: value => {return '$'+ value.toFixed(2) + ' USD'}},
        { key:'discountApplied', label: 'Descuento', formatter: value => {return value+'%'}},
        { key:'saleprice', label: 'Subtotal venta', formatter: (value, key, item) => {return '$ '+ (item.netSale/item.qty).toFixed(2) + ' USD'}},
        { key:'netSale', label: 'Total venta', formatter: value => {return '$'+ value.toFixed(2) + ' USD'}},
      ],
    }
  },
  computed:{
    ...mapState('start',['unavailabledates','payloadParamsRates']),
    ...mapState("shop", ["cart"]),
    existMainProduct() {
      return this.cart.items.some((item) => item.isMainProduct === true);
    },
    isRatesGroup(){
      return this.product.rates.some(rate => rate.type == 3)
    },
    itemTableTotals(){
      // if (this.product.modelType == 'rates') return this.product.rates
      if (this.product.modelType == 'rates' && !this.isRatesGroup) return this.cart.items.filter(prod => prod.keyRate == this.product.keyRate && !prod.show)
      else return [this.product]
    }
  },
  methods: {
    ...mapActions('start',['fetchUnavailableDates']),
    ...mapActions('shop',['calculateTotalWithDiscounts']),
    ...mapMutations('shop',['setDataProductItemRate', 'setOperationDateProduct', 'setMainProduct','copyDataMainProduct']),
    async setDataProduct(item, cost, saleprice){
      if( cost ) item.cost = cost
      if( saleprice ) {
        item.saleprice = saleprice
        item.originalSalePrice = saleprice
        item.salepriceWithDiscount = saleprice
      }

      this.setDataProductItem(item)

      if( item.modelType == 'rates' ){
        this.setDataProductItemRate(item)
      }

      if ( !!cost || !!saleprice ) await this.calculateTotalWithDiscounts()

    },
    formatPhoneNumber(event){
      let keyCode = (event.keyCode ? event.keyCode : event.which)
      //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
      const codesAllowed = [ 32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
      const respuesta = codesAllowed.includes(keyCode)
      if (!respuesta) event.preventDefault()
    },

    setDateOperation(product){
      const{ uuid, operationdate } = product
      this.setOperationDateProduct({uuid, date: operationdate })
    },
    setMain(product, e) {
      const { uuid } = product;
      const bool = e == "true" ? true : false;
      const payload = { key: uuid, bool };
      this.setMainProduct(payload);
    },
    copyProduct(product) {
      const { uuid } = product;
      this.copyDataMainProduct(uuid);
    },
    replaceInput(price){
      this.product.saleprice = structuredClone((parseFloat(price) || 0.00).toFixed(2))
      this.setDataProduct(this.product, this.product?.cost, this.product?.saleprice)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.vue__time-picker input.display-time {
    border: 1px solid #d2d2d2;
    width: 10em;
    height: 40px;
    padding: 0.3em 0.5em;
    font-size: 1em;
}
</style>