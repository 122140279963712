var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("📝"),_c('span',[_vm._v("Propiedades del artículo")])]},proxy:true}])},[_c('b-row',[(!_vm.product.isMainProduct && _vm.existMainProduct)?_c('b-col',{attrs:{"md":"12 text-right mb-2"}},[_c('b-button',{staticClass:"btn-tour-skip mr-1",attrs:{"size":"sm","variant":"btn btn-primary"},on:{"click":function($event){return _vm.copyProduct(_vm.product)}}},[_c('span',{staticClass:"mr-25 align-middle"},[_vm._v("📋 Copiar datos cliente principal")])])],1):_vm._e()],1),_c('b-table',{attrs:{"bordered":"","responsive":"sm","items":_vm.itemTableTotals,"fields":_vm.tableColumns,"sticky-header":"100"},scopedSlots:_vm._u([{key:"cell(qty)",fn:function(data){return [(data.item.modelType == 'rates')?_c('div',{staticClass:"text-nowrap"},[_c('span',[_vm._v(_vm._s(data.item.typeRate)+": ")]),_c('b',{staticClass:"float-right"},[_vm._v(_vm._s(data.value))])]):_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(data.value))])]}}])}),_c('ValidationObserver',{ref:"observer"},[(_vm.product.manualPrice)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:1","name":"Precio de venta unitario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Precio de venta unitario"}},[_c('b-form-input',{attrs:{"type":"number","step":".01","state":errors[0] ? false : (valid ? true : null),"placeholder":"Ingrese el precio"},on:{"blur":function($event){return _vm.replaceInput(_vm.product.originalSalePrice)}},model:{value:(_vm.product.originalSalePrice),callback:function ($$v) {_vm.$set(_vm.product, "originalSalePrice", $$v)},expression:"product.originalSalePrice"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,false,3844505427)})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Operation date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Fecha de operación"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"disabled":_vm.product.modelType == 'rates' || _vm.product.isGettingUnavaliableDates,"config":_vm.product.blackoutDates,"state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDateOperation(_vm.product)}},model:{value:(_vm.product.operationdate),callback:function ($$v) {_vm.$set(_vm.product, "operationdate", $$v)},expression:"product.operationdate"}})],1)}}])}),(_vm.product.isGettingUnavaliableDates)?_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-warning"},[_vm._v("Obteniendo disponibilidad")]),_c('b-spinner',{attrs:{"label":"Loading...","variant":"info"}})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"hora operación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{},[_c('label',{attrs:{"for":""}},[_vm._v("Hora de operación")]),_c('br'),_c('vue-timepicker',{attrs:{"input-width":"100%","state":errors[0] ? false : (valid ? true : null),"placeholder":"Ingrese hora de operación","minute-interval":30},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},scopedSlots:_vm._u([{key:"clearButton",fn:function(){return [_c('feather-icon',{staticStyle:{"color":"black"},attrs:{"icon":"XCircleIcon"}})]},proxy:true}],null,true),model:{value:(_vm.product.operationtime),callback:function ($$v) {_vm.$set(_vm.product, "operationtime", $$v)},expression:"product.operationtime"}}),_c('label',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"prepend":"Booking-reference"}},[_c('label',{attrs:{"for":"Booking reference"}},[_vm._v("Referencia de reserva")]),_c('b-form-input',{attrs:{"type":"text","placeholder":"Referencia de reserva"},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.bookingReference),callback:function ($$v) {_vm.$set(_vm.product, "bookingReference", $$v)},expression:"product.bookingReference"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Nombre-beneficiarios"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"prepend":"Nombre-beneficiarios"}},[_c('label',{attrs:{"for":"Nombre-beneficiarios"}},[_vm._v("Nombre beneficiario")]),_c('b-form-input',{attrs:{"type":"text","placeholder":"Nombre Completo"},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.beneficiarios.fullname),callback:function ($$v) {_vm.$set(_vm.product.beneficiarios, "fullname", $$v)},expression:"product.beneficiarios.fullname"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"email","name":"Correo-Beneficiario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"prepend":"Correo-Beneficiario"}},[_c('label',{attrs:{"for":"Correo-Beneficiario"}},[_vm._v("Correo beneficiario")]),_c('b-form-input',{attrs:{"type":"email","placeholder":"Correo"},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.beneficiarios.email),callback:function ($$v) {_vm.$set(_vm.product.beneficiarios, "email", $$v)},expression:"product.beneficiarios.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Telefono-Beneficiario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"prepend":"Telefono-Beneficiario"}},[_c('label',{attrs:{"for":"Telefono-Beneficiario"}},[_vm._v("Teléfono beneficiario")]),_c('b-form-input',{attrs:{"type":"tel","placeholder":"Teléfono","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"20"},on:{"keypress":_vm.formatPhoneNumber,"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.beneficiarios.phone),callback:function ($$v) {_vm.$set(_vm.product.beneficiarios, "phone", $$v)},expression:"product.beneficiarios.phone"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Habitacion-Beneficiario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{attrs:{"prepend":"Habitacion-Beneficiario"}},[_c('label',{attrs:{"for":"Habitacion-Beneficiario"}},[_vm._v("Habitación beneficiario")]),_c('b-form-input',{attrs:{"type":"text","placeholder":"Habitación","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"10"},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.beneficiarios.room),callback:function ($$v) {_vm.$set(_vm.product.beneficiarios, "room", $$v)},expression:"product.beneficiarios.room"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1)],1),_c('label',[_vm._v("(Si algún campo del beneficiario se deja en blanco, se autorellena con los datos del cliente)")]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max:255","name":"nota interna"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nota Interna( max: 255)"}},[_c('b-input-group',[_c('b-form-textarea',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Escribe la nota","maxlength":_vm.max,"rows":"1"},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.note),callback:function ($$v) {_vm.$set(_vm.product, "note", $$v)},expression:"product.note"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1),_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"width":"60%","cursor":"not-allowed"},attrs:{"variant":"success"}},[_vm._v("Le quedan "+_vm._s(_vm.max - _vm.product.note.length)+" caracteres")])],1)],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max:255","name":"notas cliente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nota cliente( max: 255)"}},[_c('b-input-group',[_c('b-form-textarea',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Escribe la nota","maxlength":_vm.max,"rows":"1"},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.clientnote),callback:function ($$v) {_vm.$set(_vm.product, "clientnote", $$v)},expression:"product.clientnote"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1),_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"width":"60%","cursor":"not-allowed"},attrs:{"variant":"success"}},[_vm._v("Le quedan "+_vm._s(_vm.max - _vm.product.clientnote.length)+" caracteres")])],1)],1)}}])})],1)],1),_c('b-col',{attrs:{"md":"12 "}},[_c('b-form-checkbox',{staticClass:"mt-2 mb-2 float-right",attrs:{"name":"checkbox-1","value":"true","disabled":!_vm.product.isMainProduct && _vm.existMainProduct,"unchecked-value":"false"},on:{"change":function($event){return _vm.setMain(_vm.product, $event)}},model:{value:(_vm.product.isMainProduct),callback:function ($$v) {_vm.$set(_vm.product, "isMainProduct", $$v)},expression:"product.isMainProduct"}},[_vm._v("Usar datos cliente principal ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }