<template>
  <b-tab class="contentUpgrades" >
    <template #title>⬆️<span>Upgrade</span></template>
    <ValidationObserver ref="observer"  v-slot="{  }"> 
      <b-row>

        <b-col md="12">     <h3>Detalle de upgrade</h3>   </b-col>
        <b-col md="6">

          <ValidationProvider name="Hotele" rules="required">
          <b-form-group
            slot-scope="{ valid, errors }"
            label="Seleccione hotel*"
          >
            <b-form-select
              :state="errors[0] ? false : valid ? true : null"
              v-model="product.upgradesDetail.idHotel"
              @change="handlerResort"
            >
              <option :value="null">Seleccione hotel</option>
              <option v-for="hotel in hotels" :key="hotel.id" :value="hotel.id">
                {{ hotel.name }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        
        </b-col>
          <b-col md="6">
							
								<b-form-group label="Fecha incio - Fecha final*">
									<flat-pickr
										v-model="product.upgradesDetail.rangeDate"
										class="form-control"
										@input="setCheckInChekOut"
                    :config="{ mode: 'range', minDate: 'today'}"
									/>
								</b-form-group>
							</b-col>
        <b-col md="3" v-if="product.subcategoryName == 'Plan Upgrades'">

<ValidationProvider name="mealPlan" rules="required" >
    <b-form-group label="Categoría origen" slot-scope="{ valid, errors }">
    <b-form-select
      :state="errors[0] ? false : valid ? true : null"
      v-model="product.upgradesDetail.mealPlanNameOriginName"
      
    >
      <option :value="null" >Seleccione una opción</option>
      <option
        v-for="(mealPlanItem, index) in mealPlan"
        :key="index"
        :value="mealPlanItem.id"
      >
        {{ mealPlanItem.name }}
      </option>
    </b-form-select>
    <b-form-invalid-feedback
      >{{ errors[0] }}
    </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>

      </b-col>
      <b-col md="3" v-if="product.subcategoryName == 'Plan Upgrades'">

<ValidationProvider name="mealPlan" rules="required" >
    <b-form-group label="Categoría destino" slot-scope="{ valid, errors }">
    <b-form-select
      :state="errors[0] ? false : valid ? true : null"
      v-model="product.upgradesDetail.mealPlanNameDestinationName"
      
    >
      <option :value="null">Seleccione una opción</option>
      <option
        v-for="(mealPlanItem, index) in mealPlan"
        :key="index"
        :value="mealPlanItem.id"
      >
        {{ mealPlanItem.name }}
      </option>
    </b-form-select>
    <b-form-invalid-feedback
      >{{ errors[0] }}
    </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>

      </b-col>

        <b-col md="3" v-if="product.subcategoryName == 'Room Upgrades'">
            <ValidationProvider rules="required" name="Categoría origen">
              <b-form-group
               
                slot-scope="{ valid, errors }"
                label="Categoría origen"
              >
                <b-form-select
                  v-model="product.upgradesDetail.roomsTypesOriginName"
                  :state="errors[0] ? false : valid ? true : null"
                  
                >
                <option :value="null">Seleccione una opción</option>
                <option
                  v-for="roomsItems in roomsTypes"
                  :key="roomsItems.id"
                  :value="roomsItems.code"
                >
                  {{ roomsItems.name }} | {{roomsItems.code  }}
                </option>
                </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          
        <b-col md="3" v-if="product.subcategoryName == 'Room Upgrades'">
            <ValidationProvider rules="required" name="Categoría destino" >
              <b-form-group
                
                slot-scope="{ valid, errors }"
                label="Categoría destino"
              >
                <b-form-select
                  v-model="product.upgradesDetail.roomsTypesDestinationName"
                  :state="errors[0] ? false : valid ? true : null"
                 
                >
                <option :value="null">Seleccione una opción</option>
                <option
                  v-for="roomsItems in roomsTypes"
                  :key="roomsItems.id"
                  :value="roomsItems.code"
                >
                  {{ roomsItems.name }} | {{roomsItems.code  }}
                </option>
                </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>


      </b-row>


    </ValidationObserver>
  </b-tab>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { toJson, currentDate } from '@/helpers/helpers'
import { mealPlan } from '@/data/data'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import 'vue2-timepicker/dist/VueTimepicker.css'
import flatPickr from 'vue-flatpickr-component'
import { market } from '@/modules/shop/mixins/market'

export default {
  async mounted() {
    this.handlerResort(this.product.upgradesDetail.idHotel)

    var hotels= await this.fetchHotel();
    this.setHotels(hotels)
    },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  mixins: [market],
   components: {
    VueTimepicker,
    flatPickr
  },
	watch: {
		'product.upgradesDetail': {
			deep: true,
			handler() {
      
        this.setIfRequiredFieldsFilled()
       
			},
		},	

	},
  data () {
    return {
      max: 255,
      mealPlan, 

      roomsTypes:{},
      idHotel: "",
      fechaActual: currentDate()
    }
  },
  computed:{
    ...mapState("start", ["hotels"]),
   
  },
  methods: {
    ...mapActions("start", ["fetchHotel"]),
    ...mapActions('groups', ['getRoomTypesHousing']),
    ...mapMutations("shop", [ "setIfRequiredFieldsFilled"]),
    ...mapMutations('start',['setHotels']),      
    async handlerResort(idResort){


if(idResort){
     this.roomsTypes = await this.getRoomTypesHousing(idResort)                    
                
}


},
setCheckInChekOut(rangeDate) {
			if (rangeDate.includes("to")) {
				const splitDate = rangeDate.split(" to ")
				this.product.upgradesDetail.dateIn = splitDate[0]
				this.product.upgradesDetail.dateOut = splitDate[1]
			} else {
				this.product.upgradesDetail.dateIn = null
				this.product.upgradesDetail.dateOut = null
		
			}
		},
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.contentUpgrades {
 
    min-height: 468px;
}
.contentUpgrades h3 {
    padding: 10px 0 8% 0;
}
</style>