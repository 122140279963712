var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("📋"),_c('span',[_vm._v("SOS")])]},proxy:true}])},[_c('b-tabs',{attrs:{"content-class":"mt-1"}},[_c('b-tabs',[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("🏡 Cabeceras")])]},proxy:true}])},[_c('ValidationObserver',{ref:"observer3"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Título"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Título"}},[_c('b-form-input',{attrs:{"placeholder":"Nombre del SOS","state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.orderdetailbeo.titulo),callback:function ($$v) {_vm.$set(_vm.product.orderdetailbeo, "titulo", $$v)},expression:"product.orderdetailbeo.titulo"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"Número reserva"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Núm. Reserva"}},[_c('b-form-input',{attrs:{"placeholder":"Número reserva","state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.orderdetailbeo.numreserva),callback:function ($$v) {_vm.$set(_vm.product.orderdetailbeo, "numreserva", $$v)},expression:"product.orderdetailbeo.numreserva"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Locación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"size":"sm","label":"Locación"}},[_c('b-form-input',{attrs:{"placeholder":"Ingrese locación ","state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.orderdetailbeo.locacion),callback:function ($$v) {_vm.$set(_vm.product.orderdetailbeo, "locacion", $$v)},expression:"product.orderdetailbeo.locacion"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"Backup"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"size":"sm","label":"Backup"}},[_c('b-form-input',{attrs:{"placeholder":"Ingrese locación backup","state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.orderdetailbeo.backup),callback:function ($$v) {_vm.$set(_vm.product.orderdetailbeo, "backup", $$v)},expression:"product.orderdetailbeo.backup"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"Pax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"size":"sm","label":"Pax"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"Ingrese numero de pax","state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.orderdetailbeo.pax),callback:function ($$v) {_vm.$set(_vm.product.orderdetailbeo, "pax", $$v)},expression:"product.orderdetailbeo.pax"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"max:255","name":"comentario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":(_vm.max - _vm.product.orderdetailbeo.comentario.length)+' Caracteres para comentario'}},[_c('b-form-textarea',{attrs:{"size":"sm","maxlength":_vm.max,"rows":"2","max-rows":"3","state":errors[0] ? false : (valid ? true : null)},on:{"input":function($event){return _vm.setDataProduct(_vm.product)}},model:{value:(_vm.product.orderdetailbeo.comentario),callback:function ($$v) {_vm.$set(_vm.product.orderdetailbeo, "comentario", $$v)},expression:"product.orderdetailbeo.comentario"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1)],1)],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(" 📋 Detalles")])]},proxy:true}])},[_c('ValidationObserver',{ref:"observer3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"departamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Opciones"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.selectedDpt),callback:function ($$v) {_vm.selectedDpt=$$v},expression:"selectedDpt"}},[_c('option',{attrs:{"value":""}},[_vm._v("Elija un departamento")]),_vm._l((_vm.departaments),function(field){return _c('option',{key:field.value,domProps:{"value":field}},[_vm._v(_vm._s(field.text)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"tarea"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Valor"}},[_c('b-form-textarea',{attrs:{"size":"sm","maxlength":_vm.maxTextTarea,"rows":"2","max-rows":"3","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.valorSelectDpt),callback:function ($$v) {_vm.valorSelectDpt=$$v},expression:"valorSelectDpt"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))]),_c('span',{staticClass:"text-muted"},[_vm._v("Carácteres restantes "+_vm._s(_vm.maxTextTarea - _vm.valorSelectDpt.length)+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"text-center"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"success","disabled":invalid,"size":"sm"},on:{"click":function($event){return _vm.createNewFieldsBEO()}}},[_c('b-icon',{attrs:{"icon":"plus-circle-fill"}}),_vm._v(" Añadir a lista ")],1),_c('b-button',{attrs:{"variant":"danger","disabled":invalid,"size":"sm"},on:{"click":_vm.clearInputsBEO}},[_c('b-icon',{attrs:{"icon":"x-circle-fill"}}),_vm._v(" Limpiar campos ")],1)],1)]),_c('b-col',{attrs:{"md":"12"}},[(_vm.product.orderdetailbeo ? _vm.product.orderdetailbeo.detailbeo.length : 0)?_c('table',{staticClass:"table table-bordered table-sm mt-1"},[_c('tbody',_vm._l((_vm.product.orderdetailbeo.detailbeo),function(detalle){return _c('tr',{key:detalle.key},[_c('td',[_vm._v(" "+_vm._s(detalle.property)+" ")]),_c('td',[_vm._v(" "+_vm._s(detalle.tarea)+" ")]),_c('td',[_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deleteFieldItemBEO(_vm.product,detalle)}}},[_c('b-icon',{attrs:{"icon":"trash-fill"}})],1)],1)])}),0)]):_c('b-alert',{staticClass:"mt-1",attrs:{"show":"","variant":"danger"}},[_c('div',{staticClass:"alert-body text-center"},[_vm._v(" 😣 "),_c('span',[_vm._v(" No hay tareas agregadas")])])])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }