var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tab',{staticClass:"contentUpgrades",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("⬆️"),_c('span',[_vm._v("Upgrade")])]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Detalle de upgrade")])]),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Hotele","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Seleccione hotel*"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},on:{"change":_vm.handlerResort},model:{value:(_vm.product.upgradesDetail.idHotel),callback:function ($$v) {_vm.$set(_vm.product.upgradesDetail, "idHotel", $$v)},expression:"product.upgradesDetail.idHotel"}},[_c('option',{domProps:{"value":null}},[_vm._v("Seleccione hotel")]),_vm._l((_vm.hotels),function(hotel){return _c('option',{key:hotel.id,domProps:{"value":hotel.id}},[_vm._v(" "+_vm._s(hotel.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Fecha incio - Fecha final*"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ mode: 'range', minDate: 'today'}},on:{"input":_vm.setCheckInChekOut},model:{value:(_vm.product.upgradesDetail.rangeDate),callback:function ($$v) {_vm.$set(_vm.product.upgradesDetail, "rangeDate", $$v)},expression:"product.upgradesDetail.rangeDate"}})],1)],1),(_vm.product.subcategoryName == 'Plan Upgrades')?_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"mealPlan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Categoría origen"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.product.upgradesDetail.mealPlanNameOriginName),callback:function ($$v) {_vm.$set(_vm.product.upgradesDetail, "mealPlanNameOriginName", $$v)},expression:"product.upgradesDetail.mealPlanNameOriginName"}},[_c('option',{domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.mealPlan),function(mealPlanItem,index){return _c('option',{key:index,domProps:{"value":mealPlanItem.id}},[_vm._v(" "+_vm._s(mealPlanItem.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)})],1):_vm._e(),(_vm.product.subcategoryName == 'Plan Upgrades')?_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"mealPlan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Categoría destino"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.product.upgradesDetail.mealPlanNameDestinationName),callback:function ($$v) {_vm.$set(_vm.product.upgradesDetail, "mealPlanNameDestinationName", $$v)},expression:"product.upgradesDetail.mealPlanNameDestinationName"}},[_c('option',{domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.mealPlan),function(mealPlanItem,index){return _c('option',{key:index,domProps:{"value":mealPlanItem.id}},[_vm._v(" "+_vm._s(mealPlanItem.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)})],1):_vm._e(),(_vm.product.subcategoryName == 'Room Upgrades')?_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Categoría origen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Categoría origen"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.product.upgradesDetail.roomsTypesOriginName),callback:function ($$v) {_vm.$set(_vm.product.upgradesDetail, "roomsTypesOriginName", $$v)},expression:"product.upgradesDetail.roomsTypesOriginName"}},[_c('option',{domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.roomsTypes),function(roomsItems){return _c('option',{key:roomsItems.id,domProps:{"value":roomsItems.code}},[_vm._v(" "+_vm._s(roomsItems.name)+" | "+_vm._s(roomsItems.code)+" ")])})],2)],1)}}],null,true)})],1):_vm._e(),(_vm.product.subcategoryName == 'Room Upgrades')?_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Categoría destino"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Categoría destino"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.product.upgradesDetail.roomsTypesDestinationName),callback:function ($$v) {_vm.$set(_vm.product.upgradesDetail, "roomsTypesDestinationName", $$v)},expression:"product.upgradesDetail.roomsTypesDestinationName"}},[_c('option',{domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.roomsTypes),function(roomsItems){return _c('option',{key:roomsItems.id,domProps:{"value":roomsItems.code}},[_vm._v(" "+_vm._s(roomsItems.name)+" | "+_vm._s(roomsItems.code)+" ")])})],2)],1)}}],null,true)})],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }