<template>
    <table class="table table-sm text-small mt-1">
        <tbody>
            <tr>
                <td> <label class="font-weight-bold">Adultos: </label> {{ product.adultos }}</td>
                <td> <label class="font-weight-bold">Niños: </label> {{ product.ninosTotal }}</td>
            </tr>
            <tr>
                <td> <label class="font-weight-bold">Check In: </label><br> {{ product.dateIn }}</td>
                <td> <label class="font-weight-bold">Check Out: </label><br> {{ product.dateOut }}</td>
            </tr>
            <tr>
                <td> <label class="font-weight-bold">Código Cuarto: </label><br> {{ product.roomCode }}</td>
                <td> <label class="font-weight-bold">Código Promocional: </label><br> {{ product.promotionCode || '-' }}</td>
            </tr>
            <tr>
            
                <td> <label class="font-weight-bold">Nombre: </label><br> {{ product.GivenName }}</td>
                <td> <label class="font-weight-bold">Apellidos: </label><br> {{ product.Surname }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: true,
        },       
    },
}
</script>