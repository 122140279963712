


<template>

<b-card>                
    <ValidationObserver ref="observer3" v-slot="{ invalid }" >
        <b-row>
          <b-col md="12">
            <ValidationProvider rules="required" name="inclusión">
              <b-form-group slot-scope="{ valid, errors }" label="Opciones">
                <b-form-select
                  :state="errors[0] ? false : (valid ? true : null)"
                  v-model="selectedDpt"                  
                >
                <option value="">Elija una inclusión...</option>
                <option v-for="field in dataListInclucions" :value="field"
                  :key="field.value"
                  >{{field.text}}
                </option>
              </b-form-select>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div class="text-center">
              <b-button variant="success" class="mr-2" @click="createFields(booking.uuid,booking.inclusiones)" :disabled="invalid" size="sm" >
                <b-icon icon="plus-circle-fill"></b-icon> Añadir a lista
              </b-button>
              <b-button variant="danger" @click="clearInputs" :disabled="invalid" size="sm" >
                <b-icon icon="x-circle-fill"></b-icon> Limpiar campos
              </b-button>
            </div>
          </b-col>
          <b-col md="12">
               <table class="table table-bordered table-sm mt-1" v-if="booking.inclusiones ? booking.inclusiones.length : 0" >
           
                <thead>
                  <tr>
                    <th>Inclusión</th>
                     <th>Borrar</th>
                  </tr>
                  
                </thead>
               <tbody>
              
                <tr v-for="detalle in booking.inclusiones" :key="detalle.id">                                        
                    <td> {{detalle.name}} </td> 
                    <td>
                      <b-button size="sm" variant="danger" @click="deleteFieldIncludes(booking, detalle.id)"  >
                        <b-icon icon="trash-fill"></b-icon>
                      </b-button>
                    </td>  
                </tr>                                                
              </tbody>
            </table>
         
          </b-col>
        </b-row>      
      </ValidationObserver>                                 
</b-card>       
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {

mounted(){

},
props: {
   booking: {
         type: Object,
        required: true,
        },      
    },
data () {
    return {
                                                                 
        selectedDpt : '',                  
        valorSelectDpt : '',                
    }
},       
computed:{
...mapState('shop',['discounts','dataListInclucions']),          
...mapState('start',['departaments'])            
},
methods: { 
...mapMutations('shop',['deleteInclude','setDataIncludes']),  
...mapActions('shop',['fetchInclucions']), 
                    
createFields(uuid,booking){

let valid=null
  const objeto = {
      v1:uuid,
        id: this.selectedDpt.value,
      name: this.selectedDpt.text,                
  }
  if(booking.length!=0){
valid= booking.find(item=>item.id===this.selectedDpt.value)
  }


if (valid==null){
this.setDataIncludes(objeto)
}else{
   this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: `Alerta`,
            icon: "BellIcon",
            text: `No se puede duplicar la inclusión `,
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
          timeout: 6000,
        }
      );
}

},

deleteFieldIncludes(booking, key){
  const field = JSON.parse(JSON.stringify(key))

  const obj1={
    booking,
    field
  }

  this.deleteInclude(obj1)
},            

clearInputs(){
  this.selectedDpt = null 
  this.valorSelectDpt = ''
}


}
}
</script>




