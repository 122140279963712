<template>
  <div>
    <b-row>
      <b-col md="">
        <Products :products="cart.items"/>
        <b-alert show variant="danger" v-if="cart.items.length == 0 && !isloadingProducts" class="mt-2">
          <div class="alert-body text-center"> 😣 <span>No hay productos añadidos en el carrito de compras</span></div>
        </b-alert>
      </b-col>
      <b-col md="4" v-if="cart.items.length > 0">
        <CheckoutOptions :products="cart.items" :tabIndex="tabIndex" @set-tab="setTab"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Products from '@/modules/shop/components/orderSummary/Products'
import CheckoutOptions from '@/modules/shop/components/orderSummary/CheckoutOptions'
import Ripple from 'vue-ripple-directive'
import { market } from '@/modules/shop/mixins/market'

export default {
  mixins: [ market ],
  async mounted(){
    if( !this.currencies.length ){
      const currencies = await this.fetchCurrencies()
      this.setCurrencies(currencies) //muto state currencies en start
    }
    await this.getTerms()
  },
  directives: {
    Ripple,
  },
  components: {
    // SFC
    Products,
    CheckoutOptions
  },
  data(){
    return {
      tabIndex: 1,
    }
  },
  computed:{
    ...mapState('start',['currencies']),
    ...mapState('products',['isloadingProducts']),
    ...mapState('shop',['cart']),
  },
  methods: {
    ...mapActions('start',['fetchCurrencies','fetchTerms']),
    ...mapMutations('start',['setTerms','setCurrencies']),
    async getTerms(){
      const productsBookings = this.cart.items.filter(prod => prod.modelType === 'booking').length
      if( productsBookings > 0 ){
        const termsBooking = await this.fetchTerms({typeTerms: 1, idLanguage: ''})
        this.setTerms( termsBooking )
      }
    },
    setTab(valor){
      this.tabIndex = valor
    },
  }
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';</style>


