<template>
  <b-tab>
    <template #title>📦<span>Inclusiones</span></template>

    <ValidationObserver ref="observer3" v-slot="{ invalid }">
      <b-row>
        <b-col md="12">
          <ValidationProvider rules="required" name="inclusión">
            <b-form-group slot-scope="{ valid, errors }" label="Opciones">
              <b-form-select
                :state="errors[0] ? false : valid ? true : null"
                v-model="selectedDpt"
              >
                <option value="">Elija una inclusión...</option>
                <option
                  v-for="field in dataListInclucions"
                  :value="field"
                  :key="field.value"
                >
                  {{ field.text }}
                </option>
              </b-form-select>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12 mb-3">
          <div class="text-center">
            <b-button
              variant="success"
              class="mr-2"
              @click="createFields(booking.uuid, booking.inclusions)"
              :disabled="invalid"
              size="sm"
            >
              <b-icon icon="plus-circle-fill"></b-icon> Añadir a lista
            </b-button>
            <b-button
              variant="danger"
        
              :disabled="invalid"
              size="sm"
            >
              <b-icon icon="x-circle-fill"></b-icon> Limpiar campos
            </b-button>
          </div>
        </b-col>
      </b-row>
    </ValidationObserver>

    <table class="table">
      <thead>
        <tr>
          <th scope="col">Inclusión</th>
          <th scope="col">Borrar</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="inclusion in booking.inclusions" :key="inclusion.productKey">
          <td>{{ inclusion.name }} - {{ inclusion.detaildisplay }}</td>
          <td>
            <b-button
              variant="danger"
              class="btn-sm"
              @click="deleteInclusion(inclusion.productKey)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
  </b-tab>
</template>

<script>
import { currentDate } from "@/helpers/helpers";
import { mapMutations, mapState, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedDpt: "",
      valorSelectDpt: "",
      max: 255,
      fechaActual: currentDate(),
      desgloce: [
        {
          Cantidad: this.booking.qty,
          Descuento_aplicado: this.booking.discountApplied + "%",
          Precio_venta: this.booking.saleprice,
          Total: "$" + this.total(),
        },
      ],
    };
  },
  computed: {
    ...mapState("shop", ["discounts", "dataListInclucions"]),
    ...mapState("start", ["departaments"]),
  },
  methods: {
    ...mapMutations("shop", [
      "deleteInclude", "setDataIncludes",
    ]),
    ...mapActions("shop", ["fetchInclucions"]),
    ...mapMutations("shop", ["removeInclusionToBooking"]),
    deleteInclusion(key) {
      const payload = { keyBooking: this.booking.uuid, productKey: key };
      this.removeInclusionToBooking(payload);
    },
    total() {
      return (
        Math.round(
          this.booking.qty *
            (this.booking.discountApplied > 0
              ? this.booking.salepriceWithDiscount
              : this.booking.saleprice) *
            100
        ) / 100
      ).toFixed(2);
    },
    createFields(uuid, booking) {
      let valid = null;
      const objeto = {
        v1: uuid,
        type:1,
        productKey:this.selectedDpt.value,
        detaildisplay: this.selectedDpt.text,
        name: "Inclusion extra",
      };
      if (booking.length != 0) {
        valid = booking.find(
          (item) => item.productKey === this.selectedDpt.value
        );
      }

      if (valid == null) {
        this.setDataIncludes(objeto);
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: `Alerta`,
              icon: "BellIcon",
              text: `No se puede duplicar la inclusión `,
              variant: "warning",
            },
          },
          {
            position: "bottom-right",
            timeout: 6000,
          }
        );
      }
    },
  },
};
</script>