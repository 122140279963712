<template>
  <b-tab>
    <template #title>📋<span>SOS</span></template>
    <b-tabs content-class="mt-1">
      <b-tabs>
        <b-tab active>
          <template #title>
            <span>🏡 Cabeceras</span>
          </template>
          <ValidationObserver ref="observer3">
            <b-row>
              <b-col md="6">
                <ValidationProvider rules="required" name="Título">
                  <b-form-group slot-scope="{ valid, errors }" label="Título">
                    <b-form-input placeholder="Nombre del SOS"
                      v-model="product.orderdetailbeo.titulo"
                      :state="errors[0] ? false : (valid ? true : null)"
                      @input="setDataProduct(product)"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider rules="" name="Número reserva">
                  <b-form-group slot-scope="{ valid, errors }" label="Núm. Reserva">
                    <b-form-input placeholder="Número reserva"
                      v-model="product.orderdetailbeo.numreserva" 
                      :state="errors[0] ? false : (valid ? true : null)"
                      @input="setDataProduct(product)"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
              </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider rules="required" name="Locación">
                  <b-form-group size="sm" slot-scope="{ valid, errors }" label="Locación">
                    <b-form-input
                      placeholder="Ingrese locación "
                      v-model="product.orderdetailbeo.locacion"
                      :state="errors[0] ? false : (valid ? true : null)"
                      @input="setDataProduct(product)"
                      >
                    </b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group >
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider rules="" name="Backup">
                  <b-form-group size="sm" slot-scope="{ valid, errors }" label="Backup">
                    <b-form-input
                      placeholder="Ingrese locación backup"
                      v-model="product.orderdetailbeo.backup"
                      :state="errors[0] ? false : (valid ? true : null)"
                      @input="setDataProduct(product)"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider rules="" name="Pax">
                  <b-form-group size="sm" slot-scope="{ valid, errors }" label="Pax">
                    <b-form-input
                      type="number"
                      placeholder="Ingrese numero de pax"
                      v-model="product.orderdetailbeo.pax"
                      :state="errors[0] ? false : (valid ? true : null)"
                      @input="setDataProduct(product)"
                    >
                  </b-form-input>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback></b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="12">
                <ValidationProvider rules="max:255" name="comentario">
                  <b-form-group slot-scope="{ valid, errors }" :label="(max - product.orderdetailbeo.comentario.length)+' Caracteres para comentario'">
                    <b-form-textarea 
                      size="sm"
                      v-model="product.orderdetailbeo.comentario" 
                      :maxlength="max" rows="2" max-rows="3"
                      :state="errors[0] ? false : (valid ? true : null)"
                      @input="setDataProduct(product)"
                    >
                    </b-form-textarea>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-tab>
        <b-tab>
          <template #title>
            <span> 📋 Detalles</span>
          </template>
          <ValidationObserver ref="observer3" v-slot="{ invalid }" >
            <b-row>
              <b-col md="6">
                <ValidationProvider name="departamento" rules="required">
                  <b-form-group slot-scope="{ valid, errors }" label="Opciones">
                    <b-form-select
                      :state="errors[0] ? false : (valid ? true : null)"
                      v-model="selectedDpt"
                    >
                    <option value="">Elija un departamento</option>
                    <option v-for="field in departaments" :value="field"
                      :key="field.value"
                      >{{field.text}}
                    </option>
                  </b-form-select>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider rules="required" name="tarea">
                  <b-form-group slot-scope="{ valid, errors }" label="Valor">
                    <b-form-textarea
                      size="sm"
                      v-model="valorSelectDpt"
                      :maxlength="maxTextTarea" rows="2" max-rows="3"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                    </b-form-textarea>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  <span class="text-muted">Carácteres restantes {{maxTextTarea - valorSelectDpt.length}} </span>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="12">
                <div class="text-center">
                  <b-button variant="success" class="mr-2" @click="createNewFieldsBEO()" :disabled="invalid" size="sm" >
                    <b-icon icon="plus-circle-fill"></b-icon> Añadir a lista
                  </b-button>
                  <b-button variant="danger" @click="clearInputsBEO" :disabled="invalid" size="sm" >
                    <b-icon icon="x-circle-fill"></b-icon> Limpiar campos
                  </b-button>
                </div>
              </b-col>
              <b-col md="12">
                <table class="table table-bordered table-sm mt-1" v-if="product.orderdetailbeo ? product.orderdetailbeo.detailbeo.length : 0" >
                  <tbody>
                    <tr v-for="detalle in product.orderdetailbeo.detailbeo" :key="detalle.key">
                        <td> {{detalle.property}} </td>
                        <td> {{detalle.tarea}} </td> 
                        <td>
                          <b-button size="sm" variant="danger" @click="deleteFieldItemBEO(product,detalle)" >
                            <b-icon icon="trash-fill"></b-icon>
                          </b-button>
                        </td>
                    </tr>
                  </tbody>
                </table>
                <b-alert show variant="danger" v-else class="mt-1">
                  <div class="alert-body text-center"> 😣 <span> No hay tareas agregadas</span></div>
                </b-alert>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-tab>
      </b-tabs>
    </b-tabs>
  </b-tab>
</template>

<script>
import { mapState, mapMutations} from 'vuex'
import { market } from '@/modules/shop/mixins/market'

export default {
  mixins: [market],
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      max: 255,
      maxTextTarea: 500,
      selectedDpt : null,
      valorSelectDpt : '',
    }
  },
  computed:{
    ...mapState('shop',['discounts']),
    ...mapState('start',['departaments'])
  },
  methods: {
    ...mapMutations('shop',['deleteFieldBEO','setDataBeoAreas']),
    setDataProduct(item){
      this.manageSetDataProductItem(item)
    },
    createNewFieldsBEO(){
      const key = Math.random().toString(36).substring(2,9)
      const objeto = {
        key,
        departamento: this.selectedDpt.value,
        property: this.selectedDpt.text,
        tarea: this.valorSelectDpt,
      }
      this.product.extraField = objeto
      this.setDataBeoAreas(this.product)
    },
    deleteFieldItemBEO(producto, key){
      const product = JSON.parse(JSON.stringify(producto))
      const fieldToDelete = JSON.parse(JSON.stringify(key))
      product.fieldToDelete = fieldToDelete
      this.deleteFieldBEO(product)
    },
    clearInputsBEO(){
      this.selectedDpt = null
      this.valorSelectDpt = ''
    }
  }
}
</script>