<template>
  <b-tab>
    <template #title> 🚍 <span>Itinerario</span></template>
      <ItineraryForm
        :itinerario="product.itinerario"
        :product="product"
        :isAnEdition="false"
        @handler-pax-transfer="handlerPaxTransfer"
      />
  </b-tab>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import ItineraryForm from '@/modules/shop/components/orderSummary/itinerary/itineraryForm.vue'

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    ItineraryForm
},
  data() {
    return {
      showInputPax: true
    }
  },
  methods: {
    ...mapActions('shop',['calculateTotalWithDiscounts']),
    ...mapMutations("shop", ['handlerExtraPax']),
    async handlerPaxTransfer(product){
      this.showInputPax = false
      const item = Object.assign({}, product)
      const { itinerario, originalSalePrice, uuid } = item
      const { maxPax, paxCost, pax, minPax, amountExtraPax } = itinerario

      let additionalCharge = 0
      let paxExtra = 0
      let payload  = { uuid, saleprice: originalSalePrice, salepriceWithDiscount: originalSalePrice, extraPax: 0, pax, amountExtraPax }

      if(maxPax > 0 ){
        if( pax > minPax  && pax <= maxPax){
			paxExtra = parseInt(pax) - minPax
			
			additionalCharge = paxExtra * paxCost
			const newPrice = parseFloat(originalSalePrice) +  parseFloat(additionalCharge)
		
			if( paxExtra > 0 ){
				payload.saleprice = (Math.round( newPrice * 100) / 100).toFixed(2)
				payload.salepriceWithDiscount = (Math.round( newPrice * 100) / 100).toFixed(2)
				payload.extraPax = paxExtra
				payload.amountExtraPax = (Math.round( additionalCharge * 100) / 100).toFixed(2)
			} 
        }
		//  si paxExtra es 0, pero en algun momento tenia valor distinto a 0,
		if( paxExtra === 0 ){
			payload.saleprice = originalSalePrice
			payload.salepriceWithDiscount = originalSalePrice
			payload.extraPax = 0
			payload.amountExtraPax = "0.00"
		}

        if(pax > maxPax){
			payload.saleprice = originalSalePrice
			payload.salepriceWithDiscount = originalSalePrice
			payload.extraPax = 0
			payload.pax = 0
			payload.amountExtraPax = '0.00'
        }
      }
      await this.handlerExtraPax(payload)
      await this.calculateTotalWithDiscounts()
      this.showInputPax = true
    },
  },
}
</script>