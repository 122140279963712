<template>
    <div class="discount-container">
        <div v-if="product.promoName != '' " class="mt-1">Promoción Provedor: <strong>{{product.promoName}}</strong> </div>
        <h6 class="mt-1">Aplicar descuento</h6>
        <b-input-group size="sm">
            <b-form-select
                style="width: 25%;"
                v-model="product.typeDiscount"
                :disabled="disableIfOwners"
                :options="discountsOptions"
                @change="applyTypeDiscount(product)"
            >
            </b-form-select>
            <b-form-input
                type="number"
                style="width: 25%;"
                v-model="product.discountApplied"
                @keypress="onlyNumber"
                :disabled="disabledInputDiscount(product.typeDiscount)"
                @blur="manageApplyManualDiscounts(product.discountApplied, product)"
            />
            <!-- <b-form-select
                v-model="product.discountType"
                :disabled="disableIfOwners"
                :options="discounttype"
            /> -->
        </b-input-group>
        <div
            v-if="product.typeDiscount == 'Incluido en el paquete' "
            class="mt-1"
        >
            <label>Seleccione reserva donde se va incluir</label>

            <b-form-select
                @change="handleIsIncludedIn(product)"
                v-model="product.isIncludenIn"
            >
                <option :value="null">Seleccione reserva</option>
                <option
                    v-for="({name, key}, index) in onlyBookings"
                    :key="index" :value="key"
                    >{{index + 1}} - {{name}}
                </option>
            </b-form-select>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState} from "vuex";
import { typeDiscounts, discounttype } from '@/data/data'
import { showAlertMessage } from '@/helpers/helpers'
import { market } from '@/modules/shop/mixins/market'

export default {
    mixins: [market],
    mounted(){
        this.setDiscountOptions(this.product)
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        isBooking: {
            type: Boolean,
            required: true
        }
    },

    data(){
        return {
            typeDiscounts,
            discountsOptions: [],
            discounttype,
            typeDiscount: 1
        }
    },
    watch: {
        cart: {
            deep: true,
            handler() {
                this.setDiscountOptions(this.product)
            },
        },
  },
    computed: {
        ...mapState('shop',['cart']),
        ...mapGetters("shop",["onlyBookings"]),
        disableIfOwners(){
            return this.product?.benefitDiscount || this.product?.membershipDiscount
        }
    },
    methods:{
        ...mapActions('shop',['calculateTotalWithDiscounts']),
        ...mapMutations("shop", [ "setIfRequiredFieldsFilled","addInclusionToBooking","handlerDiscount","removeInclusionToBooking", "setDataProductItem"]),
        onlyNumber($event) {
            //keyCodes value
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                // 46 is dot
                $event.preventDefault();
            }
        },
        disabledInputDiscount(typeDiscount) {
            /*Si descuento es cortesía, sin descuento o incluido en el paquete, se deshabilita input */
            const discount = this.typeDiscounts.find(disc => disc.value === typeDiscount)
            return (discount?.discount != undefined && typeDiscount != 3) || typeDiscount == 6 || this.disableIfOwners
        },

        async applyTypeDiscount(product) {
            this.setDataProductItem(product)
            this.setDiscountOptions(product)

            if( product.modelType === "rates" ){
                const prodInCart = this.cart.items.filter( experiencies => experiencies.keyRate === product.keyRate )
                prodInCart.forEach(experience => {
                    experience.typeDiscount = product.typeDiscount
                    this.manageTypeDiscount(experience)
                })
            } else this.manageTypeDiscount(product)

            await this.calculateTotalWithDiscounts()
        },
        setDiscountOptions(product){
            let typevalues = []
            this.typeDiscounts.forEach(types => {
                if (types.value != 5 && types.value != 6) typevalues.push(types)
                else if (this.isBooking && types.value == 5) typevalues.push(types)
                else if (types.value == product.typeDiscount) typevalues = {types}
            })
            this.discountsOptions = typevalues
        },
        manageTypeDiscount(product){
            const { typeDiscount, isIncludenIn} = product
            const discount = this.typeDiscounts.find(disc => disc.value === typeDiscount)

            if (discount.discount != undefined) this.handleDiscountProduct(discount.discount, product)

            if (typeDiscount == 2 ) this.handleDiscountProduct(product.descmax, product) //"Descuento"
            if (typeDiscount != 5){ // "Incluido en el paquete"
                if(isIncludenIn) this.removeInclusionToBooking({keyBooking: isIncludenIn, productKey: product.uuid })
            }
            // if (typeDiscount == 7) {
            //     const descuento = product.supplierPromo.desc;
            //     this.handleDiscountProduct(descuento, product)
            // }
        },

        async manageApplyManualDiscounts(discount, product){
            if( product.modelType === "rates" ){
                const prodInCart = this.cart.items.filter( experiencies => experiencies.keyRate === product.keyRate )
                prodInCart.forEach(experience => {
                    this.handleDiscountProduct(discount, experience)
                })
            } else this.handleDiscountProduct(discount, product)
            await this.calculateTotalWithDiscounts()
        },
        handleDiscountProduct(discount, product) {
            product.isPromo=false
            const { originalSalePrice, typeDiscount, descmax, modelType, show=false } = product
            const showMessagges = modelType !== "rates" || modelType === "rates" && show

            if (discount > 0 && discount <= 100) {
                if (typeDiscount == 2) { // "Descuento"
                    if (descmax == 0) {
                        product.typeDiscount = 1
                        product.discountApplied = 0.00
                        if (showMessagges) showAlertMessage("Atención", "InfoIcon", "El producto no admite descuento manual", "warning", 3000, "bottom-right" )
                    }
                    else if (discount > descmax) {
                        const text = `El producto tiene fijado un límite de descuento de: ${descmax}`;
                        if (showMessagges) showAlertMessage("Atención", "InfoIcon", `${text}`, "warning", 3000, "bottom-right" )
                    }
                }
                product.discountApplied = discount > descmax && typeDiscount == 2 ? descmax : discount
            } else if (discount < 1 || !discount || discount > 100) {
                if (typeDiscount == 2) {
                    product.typeDiscount = 1 // "Descuento"
                    if (showMessagges) showAlertMessage("Atención", "InfoIcon", "El producto no admite descuento manual", "warning", 3000, "bottom-right" )
                }

                product.salepriceWithDiscount = originalSalePrice;
                product.discountApplied = 0
                product.isPromo=true
                product.saleprice = parseFloat( originalSalePrice);
            }

            // const {cost, discountApplied, firstPay, id, qty, saleprice, salepriceWithDiscount, total, name, show } = product
            //recalculo el total
            this.handlerDiscount(product)
            this.setIfRequiredFieldsFilled()
            // console.log(name, show, {cost, descmax, discountApplied, firstPay, id, originalSalePrice, qty, saleprice, salepriceWithDiscount, total, typeDiscount});
        },
        handleIsIncludedIn(product){
            const { name, detaildisplay, uuid, isIncludenIn }  = product
            this.manageSetDataProductItem(product)
            //si isIncludenIn no es nulo, se añade como inclusion a un booking este producto
            if( isIncludenIn ){
                const inclusionToBooking = { name, detaildisplay, productKey: uuid, keyBooking: isIncludenIn }
                this.addInclusionToBooking( inclusionToBooking )
            }
        },
    }
}
</script>
<style scoped src="@/assets/css/styles-checkout-products.css"></style>
