<template>
  <div v-if="products.length">
    
    <b-card no-body style="max-width: 100%" v-for="product in sortItemsByBookings" :key="product.uuid" :class="product.modelType+product.show+'Experience'" >
      <b-row   no-gutters>
      
        <ImgCardProduct :product="product" />
        <div
          class="col-xs-6 col-md-6 col-sm-12"
          style="border-right: 1px solid #e0e0e0"
        >
          <b-card-body>
            <div v-if="product.hotelName" class="item-name"><label>{{ product.hotelName || '' }}{{ housing(product) }}: </label></div>
            <div v-if="product.belongsToPackage" class="item-name"><label>{{ packageName(product) || '' }}: </label></div>
            <div v-if="productName(product)" class="item-name"><label>{{formatString(product.name) || ''}}: </label></div>
            <div class="item-name"><h5>{{ product.detaildisplay || "" }}<span v-if="mainProduct(product)">⭐</span></h5></div>
            <span class="item-company">Categoría: {{ product.categoryName }} </span>
            <div v-if="product.modelType == 'rates'" class="item-company">Fecha de operación: {{ product.operationdate }} </div>
            <DiscountsHandler v-if="product.modelType != 'booking'" :product="product" :isBooking="hasBooking" />

            <div v-if="product.modelType == 'booking'">

              <InfoTableBooking :product="product" />

              <div>
                <ValidationObserver ref="termsObserver">
                  <ValidationProvider rules="required" name="terminos">
                    <b-form-group slot-scope="{ valid, errors }">
                      <label for="hotel">Terminos y condiciones*</label>
                      <v-select
                        v-model="product.term"
                        label="title"
                        :options="terms"
                        :state="errors[0] ? false : valid ? true : null"
                        @input="setDataInBooking(product)"
                      />
                      <b-form-invalid-feedback
                        >{{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </ValidationObserver>
              </div>

              <CamposRequeridosBookingMsg :product="product" v-if="product.modelType == 'booking' && product.guests" />
              <CamposRequeridosMsg :product="product" v-else-if="product.modelType == 'booking' && !product.requiredFieldsFilled " />
            </div>

            <CamposRequeridosMsg :product="product" v-if="product.modelType != 'booking' && !product.requiredFieldsFilled" />

          </b-card-body>
        </div>
        <!-- options to edit -->

        <div class="col-xs-6 col-md-3 col-sm-12" style="padding: 1rem">
          <ExperienceRatesOptions :product="product" v-if="product.modelType == 'rates'"/>
          <ProductOptions :product="product" v-else-if="product.modelType != 'booking'"/>
          <BookingOptions :product="product" v-else-if="product.modelType == 'booking' && product.categoryName != 'ReservasOwners' "/>
          <BookingOptionsOwners :product="product" v-else-if="product.categoryName == 'ReservasOwners'"/>
        </div>
      </b-row>
      <!-- para productos, eventos, paquetes -->
      <b-modal
        :id="'modal-edit-product' + product.uuid"
        ok-only
        no-close-on-backdrop
        size="lg"
        ok-title="Ok"
        centered
        :title="product.detaildisplay"
      >
        <b-tabs>
          <TabProductDetail :product="product" />
          <TabProductItinerary :product="product" v-if="showTabItinerary(product.categoryName)"/>
          <TabUpgradeDetail :product="product"  v-if="product.subcategoryName=='Room Upgrades' || product.subcategoryName=='Plan Upgrades' " />
          <TabProductBEO :product="product" v-if="product.usabeo"/>
        </b-tabs>
      </b-modal>
      <!-- para booking-->
      <b-modal
        v-if="product.modelType == 'booking'"
        :id="'modal-edit-booking' + product.uuid"
        :title="product.detaildisplay"
        ok-only
        no-close-on-backdrop
        :size="setSizeModal(product)"
        ok-title="Ok"
        centered
      >
        <b-tabs>
          <TabBookingMultiDetail :booking="product"/>
          <TabBookingInclusions :booking="product"/>
        </b-tabs>
      </b-modal>
    </b-card>
    
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex"
import { typeDiscounts } from '@/data/data'
import { toTitleCase } from '@/helpers/helpers'

import TabProductDetail from "@/modules/shop/components/orderSummary/TabProductDetail"
import TabBookingDetail from "@/modules/shop/components/orderSummary/TabBookingDetail"
import TabBookingMultiDetail from "@/modules/shop/components/orderSummary/TabBookingMultiDetail"
import TabBookingInclusions from "@/modules/shop/components/orderSummary/TabBookingInclusions"
import TabProductItinerary from "@/modules/shop/components/orderSummary/TabProductItinerary"
import TabUpgradeDetail from "@/modules/shop/components/orderSummary/TabUpgradeDetail" 
import TabProductBEO from "@/modules/shop/components/orderSummary/TabProductBEO"
import CamposRequeridosMsg from "@/modules/shop/components/orderSummary/CamposRequeridosMsg"
import CamposRequeridosBookingMsg from "@/modules/shop/components/orderSummary/CamposRequeridosBookingMsg"
import InfoTableBooking from "@/modules/shop/components/orderSummary/InfoTableBooking"
import ImgCardProduct from "@/modules/shop/components/orderSummary/ImgCardProduct"
import BookingOptions from "@/modules/shop/components/orderSummary/BookingOptions"
import BookingOptionsOwners from "@/modules/shop/components/orderSummary/BookingOptionsOwners"
import ProductOptions from "@/modules/shop/components/orderSummary/ProductOptions"
import DiscountsHandler from "@/modules/shop/components/orderSummary/DiscountsHandler"
import ExperienceRatesOptions from "@/modules/shop/components/orderSummary/ExperienceRatesOptions"

import vSelect from "vue-select"

export default {
  async mounted(){
    const product = this.products.some(product => product.modelType == 'booking' || product.usabeo)
    if (product) await this.getContentForBookings()
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      typeDiscounts,
      selectedPartial: 0,
      selectedNights: 1,
      nightsTotal: [],
      hasABooking: false
    };
  },
  components: {
    vSelect,
    //SFC
    ImgCardProduct,
    TabProductDetail,
    TabBookingDetail,
    TabBookingMultiDetail,
    TabBookingInclusions,
    InfoTableBooking,
    ProductOptions,
    BookingOptions,
    BookingOptionsOwners,
    TabProductItinerary,
    TabProductBEO,
    CamposRequeridosMsg,
    CamposRequeridosBookingMsg,
    DiscountsHandler,
    ExperienceRatesOptions,
    TabUpgradeDetail
  },
  computed: {
    ...mapState("start", ["terms"]),
    ...mapState("shop", ["cart"]),
    ...mapGetters("shop",["onlyBookings"]),
    ...mapState('shop',['cart']),
    sortItemsByBookings(){
      const items = this.cart.items
      return items.sort((a,b) => (a.modelType > b.modelType) ? 1 : ((b.modelType > a.modelType) ? -1 : 0))
    },
    existsAMain(){
      const booking = this.cart.items.some( item => item.isMain === true )
      const producto = this.cart.items.some( item => item.isMainProduct === true )
      return {booking, producto}
    },
    hasBooking(){
      const items = this.cart.items
      const hasABooking = items.find((product)=>product.modelType=='booking')
      return hasABooking ? true : false
    }
  },
  methods: {
    ...mapActions('shop',['getContentForBookings']), 
    ...mapMutations("shop", ["setDataBookingItem"]),
    showTabItinerary(categoryName) {
      const categorias = ["Transfer"]
      return categorias.includes(categoryName)
    },
    showTabUpgadreDetail(subcategoryName) {
      const subcategory = ["cambio de rooms"]
      return subcategory.includes(subcategoryName?.name)
    },
    setDataInBooking(data) {
      this.setDataBookingItem(data)
    },
    formatString(str) {
      return toTitleCase(str)
    },
    productName(product){
      return product?.name && product?.name != product?.detaildisplay && product?.name != '' && product?.categoryName != 'ReservasOwners'
    },
    packageName(product){
      const paquete = this.cart.items.find( item => item.uuid == product.uuidPackage )
      return toTitleCase(paquete.name)
    },
    mainProduct(product){
      const booking = product.isMain && this.existsAMain.booking
      const producto = product.isMainProduct && this.existsAMain.producto
      return booking || producto
    },
    setSizeModal(product){
      return product.guests?.childs?.length > 0 ? 'xl' : 'lg'
    },
    housing(item){
      return item?.housingnumber ? ` | ${item?.housingnumber}` : ''
    }
  }
}
</script>
<style lang="scss">
.ratesfalseExperience{
  display: none;
}
</style>


