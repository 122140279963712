var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("📝"),_c('span',[_vm._v("Datos")])]},proxy:true}])},[_c('div',{staticClass:"pb-1 text-right"},[((!_vm.booking.isMain && _vm.existBookingMain) && _vm.booking.categoryName != 'ReservasOwners')?_c('b-button',{staticClass:"btn-tour-skip",attrs:{"size":"sm","variant":"btn btn-primary"},on:{"click":_vm.copyBooking}},[_c('span',{staticClass:"mr-25 align-middle"},[_vm._v("📋 Copiar datos cliente principal")])]):_vm._e()],1),(_vm.booking.categoryName && _vm.booking.categoryName == 'ReservasOwners')?_c('b-row',[_c('b-col',[_c('BookingGuestsOwners',{attrs:{"guests":_vm.booking.guests.adults,"owners":_vm.booking.owners,"bookingData":_vm.booking},on:{"set-guests-in-properties":_vm.setDataInGuests}})],1),(_vm.booking.guests.childs.length > 0)?_c('b-col',{attrs:{"md":"6"}},[_c('BookingGuestsOwners',{attrs:{"guests":_vm.booking.guests.childs,"bookingData":_vm.booking},on:{"set-guests-in-properties":_vm.setDataInGuests}})],1):_vm._e()],1):_c('b-row',[_c('b-col',[_c('BookingGuests',{attrs:{"guests":_vm.booking.guests.adults},on:{"set-guests-in-properties":_vm.setDataInGuests}})],1),(_vm.booking.guests.childs.length > 0)?_c('b-col',{attrs:{"md":"6"}},[_c('BookingGuests',{attrs:{"guests":_vm.booking.guests.childs},on:{"set-guests-in-properties":_vm.setDataInGuests}})],1):_vm._e()],1),_c('hr'),_c('b-card',[_c('b-card-title',[_c('h5',[_vm._v("Notas")])]),_c('b-row',[(_vm.booking.categoryName != 'ReservasOwners')?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max:255","name":"notas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nota Interna( max: 255)"}},[_c('b-form-group',[_c('b-form-textarea',{attrs:{"state":errors[0] ? false : valid ? true : null,"placeholder":"Escribe la nota","maxlength":_vm.max,"rows":"1"},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.note),callback:function ($$v) {_vm.$set(_vm.booking, "note", $$v)},expression:"booking.note"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1),_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"width":"60%","cursor":"not-allowed"},attrs:{"variant":"success"}},[_vm._v("Le quedan "+_vm._s(_vm.max - _vm.booking.note.length)+" caracteres")])],1)],1)}}],null,false,3217902046)})],1):_vm._e(),(_vm.booking.categoryName != 'ReservasOwners')?_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max:255","name":"nota cliente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nota Cliente( max: 255)"}},[_c('b-form-group',[_c('b-form-textarea',{attrs:{"state":errors[0] ? false : valid ? true : null,"placeholder":"Escribe la nota","maxlength":_vm.max,"rows":"1"},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.clientnote),callback:function ($$v) {_vm.$set(_vm.booking, "clientnote", $$v)},expression:"booking.clientnote"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1),_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"width":"60%","cursor":"not-allowed"},attrs:{"variant":"success"}},[_vm._v(" Le quedan "+_vm._s(_vm.max - _vm.booking.clientnote.length)+" caracteres ")])],1)],1)}}],null,false,2860364809)})],1):_vm._e(),(_vm.booking.categoryName == 'ReservasOwners')?_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"max:255","name":"Notas Prearrival"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":""}},[_c('b-form-group',[_c('b-form-textarea',{attrs:{"state":errors[0] ? false : valid ? true : null,"placeholder":"Escribe la nota","maxlength":_vm.max,"rows":"1"},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.note),callback:function ($$v) {_vm.$set(_vm.booking, "note", $$v)},expression:"booking.note"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)],1)}}],null,false,2828199672)})],1):_vm._e()],1)],1),(_vm.booking.categoryName != 'ReservasOwners')?_c('b-form-checkbox',{staticClass:"mt-2 mb-2 float-right",attrs:{"name":"checkbox-1","value":"true","disabled":!_vm.booking.isMain && _vm.existBookingMain,"unchecked-value":"false"},on:{"change":function($event){return _vm.setMain(_vm.booking, $event)}},model:{value:(_vm.booking.isMain),callback:function ($$v) {_vm.$set(_vm.booking, "isMain", $$v)},expression:"booking.isMain"}},[_vm._v(" Usar datos cliente principal ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }