<template>
  <div>
  
  <b-tab active>
    <template #title>📝<span>Datos</span></template>
    <ValidationObserver ref="observerBooking">
      <b-row>
    
        <b-col
          md="12 text-right  mb-2"
          v-if="!booking.isMain && existBookingMain"
        >
          <b-button
            size="sm"
            variant="btn btn-primary"
            class="btn-tour-skip mr-1"
            @click="copyBooking"
          >
            <span class="mr-25 align-middle"> 📋  Copiar datos cliente principal</span>
          </b-button>
        </b-col>
        <b-col md="6">
          <ValidationProvider name="Prefijo" rules="required">
            <b-form-group label="Prefijo" slot-scope="{ valid, errors }">
              <b-form-select
                :state="errors[0] ? false : valid ? true : null"
                v-model="booking.NamePrefix"
                @input="setDataInBooking(booking)"
              >
                <option value="">Seleccione una opción</option>
                <option
                  v-for="(namesF, index) in namesPrefix"
                  :key="index"
                  :value="namesF.value"
                >
                  {{ namesF.text }}
                </option>
              </b-form-select>
              <b-form-invalid-feedback
                >{{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="6">
          <ValidationProvider rules="required" name="GivenName">
            <b-form-group label="Nombre" slot-scope="{ valid, errors }">
              <b-form-input
                type="text"
                v-model="booking.GivenName"
                :state="errors[0] ? false : valid ? true : null"
                @input="setDataInBooking(booking)"
              >
              </b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="6">
          <ValidationProvider rules="required" name="Apellido">
            <b-form-group label="Apellido" slot-scope="{ valid, errors }">
              <b-form-input
                type="text"
                v-model="booking.Surname"
                :state="errors[0] ? false : valid ? true : null"
                @input="setDataInBooking(booking)"
              >
              </b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="6">
          <ValidationProvider rules="required" name="Número de teléfono">
            <b-form-group
              label="Número de teléfono"
              slot-scope="{ valid, errors }"
            >
              <b-form-input
                type="number"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                v-model="booking.PhoneNumber"
                maxlength="10"
                :state="errors[0] ? false : valid ? true : null"
                @input="setDataInBooking(booking)"
              >
              </b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="6">
          <ValidationProvider rules="required|email" name="email">
            <b-form-group label="Email" slot-scope="{ valid, errors }">
              <b-form-input
                type="email"
                v-model="booking.Email"
                :state="errors[0] ? false : valid ? true : null"
                @input="setDataInBooking(booking)"
              >
              </b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <ValidationProvider rules="max:255" name="notas">
            <b-form-group
              slot-scope="{ valid, errors }"
              label="Nota Interna( max: 255)"
            >
              <b-form-group>
                <b-form-textarea
                  v-model="booking.note"
                  :state="errors[0] ? false : valid ? true : null"
                  placeholder="Escribe la nota"
                  :maxlength="max"
                  rows="1"
                  @input="setDataInBooking(booking)"
                >
                </b-form-textarea>
                <b-form-invalid-feedback>{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
              <div class="text-center">
                <b-badge
                  variant="success"
                  style="width: 60%; cursor: not-allowed"
                  >Le quedan {{ max - booking.note.length }} caracteres</b-badge
                >
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="6">
          <ValidationProvider rules="max:255" name="nota cliente">
            <b-form-group
              slot-scope="{ valid, errors }"
              label="Nota Cliente( max: 255)"
            >
              <b-form-group>
                <b-form-textarea
                  v-model="booking.clientnote"
                  :state="errors[0] ? false : valid ? true : null"
                  placeholder="Escribe la nota"
                  :maxlength="max"
                  rows="1"
                  @input="setDataInBooking(booking)"
                >
                </b-form-textarea>
                <b-form-invalid-feedback>{{
                  errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
              <div class="text-center">
                <b-badge
                  variant="success"
                  style="width: 60%; cursor: not-allowed"
                  >Le quedan
                  {{ max - booking.clientnote.length }} caracteres</b-badge
                >
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col md="12 ">
          <b-form-checkbox
            class="mt-2 mb-2 float-right"
            v-model="booking.isMain"
            name="checkbox-1"
            value="true"
            :disabled="!booking.isMain && existBookingMain"
            unchecked-value="false"
            @change="setMain(booking, $event)"
          >
            Usar datos cliente principal
          </b-form-checkbox>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-tab>


  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import { namesPrefix } from '@/data/data';
import Includes from '@/modules/mySales/components/orderDetail/IncludesTabs.vue';


export default {
  components: { Includes,  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      max: 255,
      namesPrefix,
    };
  },
  computed: {
    ...mapState("shop", ["cart"]),
    ...mapGetters('mySales',['orderDetailPackages','orderDetailIncludes']),

    orderHasProductsWithBEO(){
        return this.orderDetailPackages.find( product => product.orderdetailbeo.id != "" )                    
    },
    existBookingMain() {
      return this.cart.items.find((item) => item.isMain === true);
    },
  },
  methods: {
    ...mapMutations("shop", [
      "setDataBookingItem",
      "setMainBooking",
      "copyDataMainBooking",
    ]),
    setDataInBooking(data) {
      this.setDataBookingItem(data);
    },
    setMain(booking, e) {
      const { uuid } = booking;
      const bool = e == "true" ? true : false;
      const payload = { key: uuid, bool };
      this.setMainBooking(payload);
    },
    copyBooking() {
      const { uuid } = this.booking;
      this.copyDataMainBooking(uuid);
    },
  },
};
</script>


<style scoped>

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #177bd2;
  background-color: #267dc7 !important;
}

label.custom-control-label {
  font-weight: 600;
}
</style>