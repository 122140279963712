<template>
  <div class="checkout-options" v-if="cart.cliente.currency">
    <b-card no-body class="border-primary" >
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
        <h5 class="mb-0">
          Total purchase
        </h5>
        <b-badge variant="success">
          {{cart.cliente.currency.code}}
        </b-badge>
        <small class="text-muted w-100">Elija una divisa para la conversión</small>
      </b-card-header>
      <b-card-body>
        <b-form-select id="divisaSelect" @change="calcularImportes" v-model="cart.cliente.currency" v-if="cart.totalBreakdown.total > 0">
            <option v-for="cur in currencies" :key="cur.id" :value="cur">{{cur.code}}</option>
        </b-form-select>
        <div class="d-flex justify-content-between mt-1 mb-1" v-if="subTotalParcial != 0 ">
          <div>
            <b>Primer pago parcial Reserva: </b><b-badge variant="light-secondary"> $ {{subTotalParcial}} </b-badge>
          </div>
          <div>
            <b>Primer pago parcial: </b><b-badge variant="light-secondary"> $ {{subTotalParcialBooking}} </b-badge>
          </div>
        </div>
        <div class="d-flex justify-content-between mb-2 mt-1">
          <div>
            <b>Tipo de cambio: </b><b-badge variant="light-secondary"> {{tc}} </b-badge><br>
            <b>Total divisa : </b><b-badge variant="light-secondary"> ${{totalDivisaParcial}} </b-badge>
          </div>
          <div>
            <b>SubTotal:</b><b-badge variant="light-secondary">${{subTotal}} </b-badge> <br>
            <b>Descuento: </b><b-badge variant="light-secondary">${{ totalDiscount }} </b-badge> <br>
            <b>Total:</b> <b-badge variant="light-secondary">${{total}} </b-badge>
          </div>
        </div>

        <div class="checkout-options p-1 mb-1 border rounded border-danger" v-if="cart.requiredFields.length > 0">
          <!-- <hr> -->
          <label>Por favor, llene las casillas de los siguientes artículos: </label> <br>
          <span v-for="(requiredFields, index) in cart.requiredFields" :key="index">
            <label>
              <strong>{{ index + 1 }} -  </strong> {{ requiredFields}}
            </label> <br>
          </span>
        </div>

        <b-row>
          <b-col v-if="bookingSinAuthorization > 0">
            <b-button variant="primary" @click="openModalAuthorizeBooking">
              <feather-icon icon="UnlockIcon"/> Autorizar bookings
            </b-button>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              block
              :to="{ name: 'payment' }"
              :disabled="disablePaymentFlow"
            > Finalizar Venta
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <ModalAuthorize/>
  </div>

</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import ModalAuthorize from "@/modules/shop/components/orderSummary/ModalAuthorize"
import { toJson } from '@/helpers/helpers'

export default {
  components:{
    ModalAuthorize
  },
  mounted(){
    this.currencieValor = this.cart.cliente.currency ? this.cart.cliente.currency.value : 1
    this.cart.cliente.currency=this.cart.cliente.currency
  },
  data() {
    return {
      currencieValor:1
      // imgUrl: process.env.VUE_APP_IMG_SRC_API,
    }
  },
  computed:{
    ...mapState('shop',['cart']),
    ...mapState('start',['currencies']),
    ...mapGetters('shop',['totalBreakdown','subTotal', 'subTotalParcial', 'subTotalParcialBooking', 'total', 'totalDiscount', 'tc', 'totalDivisaParcial']),
    bookingSinAuthorization(){
      const cart = this.cart.items
      return cart.filter( b => b.modelType === "booking" && b.requiresAuthorization ).length
    },
    disablePaymentFlow(){
      return this.cart.items.length == 0 || this.cart.requiredFields.length > 0
    }
  },
  methods:{
    ...mapMutations('shop',['setCurrency']),
    calcularImportes(){
      this.currencieValor = this.cart.cliente.currency ? this.cart.cliente.currency.value : 1
      this.setCurrency(this.cart.cliente.currency)
    },
    setTab(){
      this.$emit('set-tab', 1)
    },
    openModalAuthorizeBooking() {
      const modal = "authorize-booking"
      const btnShowBooking = "btnShowBooking"
      this.$root.$emit("bv::show::modal", modal, btnShowBooking)
    },
  },
  watch: {
    totalBreakdown: {
      deep: true,
      handler() {
        if( this.totalBreakdown === '0.00' && this.currencies.length > 0 ) {
          const divisa = toJson( this.currencies.find( currency => currency.code === 'USD' ))
          this.setCurrency( divisa )
        }
      },
    },
  },
}
</script>


<style lang="scss" scoped>

.card-img-top {
  width: 100%;
  height: 25vh;
  object-fit: cover;
}
.ecommerce-application .grid-view .ecommerce-card .item-img {
    padding-top: 0rem;
    min-height: 0rem;
    display: flex;
    align-items: center;
}

.detail-totalParcial{
color:crimson;
font-weight:bold;

}
</style>