<template>
    <label :class="textLabel" v-if="!camposPorLLenar">

        <span v-if="showMessage"><li>{{messagefieldsrequired}}</li></span>
        <!-- <span v-if="showMessage">> </span> -->

        <span v-for="item in camposFaltantes" :key="item.key">
            <b-badge class="badgemargin" :id="`tooltip${item.key}`" variant="danger" >{{item.guest}}</b-badge>
            <b-tooltip :target="`tooltip${item.key}`" triggers="hover">
                <span v-for="(campos, index) in item.campos" :key="index" >
                    <li v-if="campos">{{campos}}</li>
                </span>
            </b-tooltip>
        </span>

        <br v-if="mensajesEpeciales.length > 0">
        <span v-for="(item, index) in mensajesEpeciales" :key="item.key">
            <li>{{item.msg}}</li>
            <br v-if="index != mensajesEpeciales.length - 1"/>
        </span>

    </label>
</template>

<script>
import {  mapState } from 'vuex'

import { camposRequeridosDeBookingPorLLenar } from '@/helpers/helpers'

export default {
    mounted() {
        this.camposPorLLenar
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selectedProduct: null,
            messagefieldsrequired: '',
            showMessage: false,
            camposFaltantes: null,
            mensajesEpeciales: [],
            Allfilled: true
        }
    },
    computed:{
        ...mapState('appConfig',['layout']),
        textLabel(){
            return this.layout.skin === 'dark' ? 'color-dark-text': 'color-light-text'
        },
        camposPorLLenar() {
            const data = camposRequeridosDeBookingPorLLenar(this.product) //helper
            if (data) {
                const {camposFaltantes, message, specialMessage, Allfilled} = data
                this.messagefieldsrequired = message
                this.showMessage = camposFaltantes.length > 0
                this.camposFaltantes = camposFaltantes
                this.mensajesEpeciales = specialMessage
                this.Allfilled = Allfilled
            } else {
                this.messagefieldsrequired = ''
                this.camposFaltantes = null
                this.mensajesEpeciales = []
                this.Allfilled = true
            }
            return this.Allfilled
        },
    }
}
</script>
<style scoped>
.color-dark-text {
    color: #ff3d00;
    font-weight: bold;
}
.color-light-text {
    color: red;
}
.badgemargin{
  margin: 3px 5px 3px 0;
}
</style>