var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("📝"),_c('span',[_vm._v("Datos")])]},proxy:true}])},[_c('ValidationObserver',{ref:"observerBooking"},[_c('b-row',[(!_vm.booking.isMain && _vm.existBookingMain)?_c('b-col',{attrs:{"md":"12 text-right  mb-2"}},[_c('b-button',{staticClass:"btn-tour-skip mr-1",attrs:{"size":"sm","variant":"btn btn-primary"},on:{"click":_vm.copyBooking}},[_c('span',{staticClass:"mr-25 align-middle"},[_vm._v(" 📋 Copiar datos cliente principal")])])],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Prefijo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Prefijo"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.NamePrefix),callback:function ($$v) {_vm.$set(_vm.booking, "NamePrefix", $$v)},expression:"booking.NamePrefix"}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.namesPrefix),function(namesF,index){return _c('option',{key:index,domProps:{"value":namesF.value}},[_vm._v(" "+_vm._s(namesF.text)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"GivenName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nombre"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.GivenName),callback:function ($$v) {_vm.$set(_vm.booking, "GivenName", $$v)},expression:"booking.GivenName"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Apellido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Apellido"}},[_c('b-form-input',{attrs:{"type":"text","state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.Surname),callback:function ($$v) {_vm.$set(_vm.booking, "Surname", $$v)},expression:"booking.Surname"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Número de teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Número de teléfono"}},[_c('b-form-input',{attrs:{"type":"number","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","maxlength":"10","state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.PhoneNumber),callback:function ($$v) {_vm.$set(_vm.booking, "PhoneNumber", $$v)},expression:"booking.PhoneNumber"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{attrs:{"type":"email","state":errors[0] ? false : valid ? true : null},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.Email),callback:function ($$v) {_vm.$set(_vm.booking, "Email", $$v)},expression:"booking.Email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max:255","name":"notas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nota Interna( max: 255)"}},[_c('b-form-group',[_c('b-form-textarea',{attrs:{"state":errors[0] ? false : valid ? true : null,"placeholder":"Escribe la nota","maxlength":_vm.max,"rows":"1"},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.note),callback:function ($$v) {_vm.$set(_vm.booking, "note", $$v)},expression:"booking.note"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1),_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"width":"60%","cursor":"not-allowed"},attrs:{"variant":"success"}},[_vm._v("Le quedan "+_vm._s(_vm.max - _vm.booking.note.length)+" caracteres")])],1)],1)}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max:255","name":"nota cliente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nota Cliente( max: 255)"}},[_c('b-form-group',[_c('b-form-textarea',{attrs:{"state":errors[0] ? false : valid ? true : null,"placeholder":"Escribe la nota","maxlength":_vm.max,"rows":"1"},on:{"input":function($event){return _vm.setDataInBooking(_vm.booking)}},model:{value:(_vm.booking.clientnote),callback:function ($$v) {_vm.$set(_vm.booking, "clientnote", $$v)},expression:"booking.clientnote"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1),_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"width":"60%","cursor":"not-allowed"},attrs:{"variant":"success"}},[_vm._v("Le quedan "+_vm._s(_vm.max - _vm.booking.clientnote.length)+" caracteres")])],1)],1)}}])})],1),_c('b-col',{attrs:{"md":"12 "}},[_c('b-form-checkbox',{staticClass:"mt-2 mb-2 float-right",attrs:{"name":"checkbox-1","value":"true","disabled":!_vm.booking.isMain && _vm.existBookingMain,"unchecked-value":"false"},on:{"change":function($event){return _vm.setMain(_vm.booking, $event)}},model:{value:(_vm.booking.isMain),callback:function ($$v) {_vm.$set(_vm.booking, "isMain", $$v)},expression:"booking.isMain"}},[_vm._v(" Usar datos cliente principal ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }