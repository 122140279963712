<template>
	<ValidationObserver ref="observerBooking">
		<b-card>
			<div v-for="(guest, index) in guests" :key="guest.idForm">
				<b-card-title>
					<h5>{{cardTitle({guest, index})}} </h5>
					<label v-if="guest.mainGuest">Adulto 1</label>
					<label v-if="!guest.Adult">Edad: {{guest.Age}}</label>
				</b-card-title>
				<b-row class="mb-1">
					<b-col md="6" v-if="guest.mainGuest">
						<label>Tipo de ocupación</label>
						<validation-provider name="tipo de ocupacion" rules="required">
							<b-form-group slot-scope="{ valid, errors }">
								<b-form-select
									v-model="bookingData.occupanttype"
									:state="errors[0] ? false : valid ? true : null"
									size="sm"
									@input="unsetMainOwner(guest)"
								>
								<option :value="null" disabled>Seleccione una opción</option>
								<option v-for="(item, index) in occupanttype" :key="index" :value="item.id" > {{ item.name }} </option>
								</b-form-select>
								<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>
					</b-col>
					<b-col md="6" class="mb-1" v-if="guest.mainGuest && bookingData.occupanttype == '1'">
						<label>Seleccione un propietario</label>
							<b-form-select
								size="sm"
								v-model="bookingData.selectedOwner"
								@input="setMainOwner(guest)"
							>
								<option :value="null" disabled>Seleccione una opción</option>
								<option
									v-for="(owner, index) in owners"
									:key="index"
									:value="owner"
								>
								{{ owner.ownerName }} {{ owner.ownerLastname }}
								</option>
							</b-form-select>
					</b-col>
				</b-row>
				<hr v-if="guest.mainGuest">
				<b-row>
					<b-col v-if="guest.mainGuest">
					<ValidationProvider name="Prefijo" rules="required">
						<b-form-group label="Prefijo" slot-scope="{ valid, errors }">
						<b-form-select
							:state="errors[0] ? false : valid ? true : null"
							v-model="guest.NamePrefix"
							@input="setDataInBooking(guests)"
						>
							<option value="">Seleccione una opción</option>
							<option
								v-for="(namesF, index) in namesPrefix"
								:key="index"
								:value="namesF.value"
							>
								{{ namesF.text }}
							</option>
						</b-form-select>
						<b-form-invalid-feedback
							>{{ errors[0] }}
						</b-form-invalid-feedback>
						</b-form-group>
					</ValidationProvider>
					</b-col>
					<b-col>
					<ValidationProvider rules="required" name="Nombre">
						<b-form-group label="Nombre" slot-scope="{ valid, errors }">
						<b-form-input
							:disabled="hasNoRequiredData(guest)"
							type="text"
							v-model="guest.GivenName"
							:state="errors[0] ? false : valid ? true : null"
							@input="setDataInBooking(guests)"
						>
						</b-form-input>
						<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
						</b-form-group>
					</ValidationProvider>
					</b-col>
					<b-col>
					<ValidationProvider rules="required" name="Apellido">
						<b-form-group label="Apellido" slot-scope="{ valid, errors }">
						<b-form-input
							:disabled="hasNoRequiredData(guest)"
							type="text"
							v-model="guest.Surname"
							:state="errors[0] ? false : valid ? true : null"
							@input="setDataInBooking(guests)"
						>
						</b-form-input>
						<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
						</b-form-group>
					</ValidationProvider>
					</b-col>
				</b-row>

				<b-row v-if="guest.Adult && guest.mainGuest">
					<b-col>
					<ValidationProvider rules="required" name="Teléfono">
						<b-form-group
						label="Teléfono"
						slot-scope="{ valid, errors }"
						>
						<b-form-input
							:disabled="hasNoRequiredData(guest)"
							type="number"
							@keypress="formatPhoneNumber"
							v-model="guest.PhoneNumber"
							maxlength="10"
							:state="errors[0] ? false : valid ? true : null"
							@input="setDataInBooking(guests)"
						>
						</b-form-input>
						<b-form-invalid-feedback>
							{{ errors[0] }}
						</b-form-invalid-feedback>
						</b-form-group>
					</ValidationProvider>
					</b-col>
					<b-col>
					<ValidationProvider rules="required|email" name="Email">
						<b-form-group label="Email" slot-scope="{ valid, errors }">
						<b-form-input
							:disabled="hasNoRequiredData(guest)"
							type="email"
							v-model="guest.Email"
							:state="errors[0] ? false : valid ? true : null"
							@input="setDataInBooking(guests)"
						>
						</b-form-input>
						<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
						</b-form-group>
					</ValidationProvider>
					</b-col>
				</b-row>

				<hr v-if="((index+1) < guests.length)">
			</div>
		</b-card>
	</ValidationObserver>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { namesPrefix, namesPrefixId } from '@/data/data'
import { occupanttype } from '@/data/fivesClub'

export default {
  props: {
    guests: {
      type: Array,
      required: true,
    },
    owners: {
      type: Array
    },
    bookingData: {
      type: Object
    }
  },
  data(){
    return {
      occupanttype,
      namesPrefix,
      namesPrefixId,
      childages: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]
    }
  },
  computed: {
    ...mapState('shop', ['selectedOwnerFromBooking', 'cart']),
  },
  methods: {
    ...mapMutations('shop', ['setInfoClient']),
    setDataInBooking(data){
      this.$emit('set-guests-in-properties', data)
    },
    cardTitle(data){
      const {guest, index} = data
      if (guest.mainGuest) return `Main Guest: `
      else return guest.Adult ? `Adulto ${index+1}: ${guest.GivenName} ${guest.Surname}` : `Niño ${index+1}: ${guest.GivenName} ${guest.Surname}`
    },
    rulesRequired(data){
      const {index, adult} = data
      return adult && index == 0 ? 'required' : ''
    },
    setMainOwner(guest){
      if (guest?.mainGuest){
        const selectedOwner = structuredClone(this.bookingData?.selectedOwner)

        guest.NamePrefix = selectedOwner != null ? (namesPrefixId.find(prefix => prefix.id == selectedOwner?.ownerPrefix)?.name || '') : ''
        guest.GivenName = selectedOwner != null ? (selectedOwner?.ownerName || '') : ''
        guest.Surname = selectedOwner != null ? (selectedOwner?.ownerLastname || '') : ''
        guest.PhoneNumber = selectedOwner != null ? (selectedOwner?.ownerPhone || '') : ''
        guest.Email = selectedOwner != null ? (selectedOwner?.ownerEmail || '') : ''

        this.setInfoClient({
          name: selectedOwner?.ownerName,
          lastname: selectedOwner?.ownerLastname,
          email: selectedOwner?.ownerEmail,
          phone: selectedOwner?.ownerPhone,
          id: selectedOwner?.idOwner,
          customertype: 2
        })
      }
    },
    unsetMainOwner(guest){
      this.bookingData.selectedOwner = null

      if (guest?.mainGuest){
        guest.NamePrefix = ''
        guest.GivenName = ''
        guest.Surname = ''
        guest.PhoneNumber = ''
        guest.Email = ''

        this.setInfoClient({
          name: this.owners[0]?.ownerName,
          lastname: this.owners[0]?.ownerLastname,
          email: this.owners[0]?.ownerEmail,
          phone: this.owners[0]?.ownerPhone,
          id: this.owners[0]?.idOwner,
          customertype: 2
        })
      }
    },
    hasNoRequiredData(guest){
      if (this.bookingData.occupanttype == 1) this.guests.forEach(guest => { if (!guest.mainGuest) {
        if (guest.GivenName == '') guest.GivenName = 'N/A'
        if (guest.Surname == '') guest.Surname = 'N/A'
      }})

      return guest?.mainGuest && this.bookingData.occupanttype == 1
    },
    formatPhoneNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
      const codesAllowed = [32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
      const respuesta = codesAllowed.includes(keyCode);
      if (!respuesta) event.preventDefault()
    },
  }
}
</script>

<style>
.margin-half{
  margin-top: 0.4rem !important;
}
</style>