<template>
  <b-tab active>
    <template #title>📝<span>Datos</span></template>

    <div class="pb-1 text-right">
      <b-button
        v-if="(!booking.isMain && existBookingMain) && booking.categoryName != 'ReservasOwners'"
        size="sm"
        variant="btn btn-primary"
        class="btn-tour-skip"
        @click="copyBooking"
      >
        <span class="mr-25 align-middle">📋 Copiar datos cliente principal</span>
      </b-button>
    </div>

    <b-row v-if="booking.categoryName && booking.categoryName == 'ReservasOwners'">
      <b-col>
        <BookingGuestsOwners
          :guests="booking.guests.adults"
          :owners="booking.owners"
          :bookingData="booking"
          @set-guests-in-properties="setDataInGuests"
        />
      </b-col>
      <b-col md="6" v-if="booking.guests.childs.length > 0">
        <BookingGuestsOwners
          :guests="booking.guests.childs"
          :bookingData="booking"
          @set-guests-in-properties="setDataInGuests"
        />
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col>
        <BookingGuests
          :guests="booking.guests.adults"
          @set-guests-in-properties="setDataInGuests"
        />
      </b-col>
      <b-col md="6" v-if="booking.guests.childs.length > 0">
        <BookingGuests
          :guests="booking.guests.childs"
          @set-guests-in-properties="setDataInGuests"
        />
      </b-col>
    </b-row>

    <hr>

    <b-card>
      <b-card-title><h5>Notas</h5></b-card-title>
      <b-row>
        <b-col md="6" v-if="booking.categoryName != 'ReservasOwners'">
          <ValidationProvider rules="max:255" name="notas">
            <b-form-group
              slot-scope="{ valid, errors }"
              label="Nota Interna( max: 255)"
            >
              <b-form-group>
                <b-form-textarea
                  v-model="booking.note"
                  :state="errors[0] ? false : valid ? true : null"
                  placeholder="Escribe la nota"
                  :maxlength="max"
                  rows="1"
                  @input="setDataInBooking(booking)"
                >
                </b-form-textarea>
                <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
              </b-form-group>
              <div class="text-center">
                <b-badge
                  variant="success"
                  style="width: 60%; cursor: not-allowed"
                  >Le quedan {{ max - booking.note.length }} caracteres</b-badge
                >
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="6" v-if="booking.categoryName != 'ReservasOwners'">
          <ValidationProvider rules="max:255" name="nota cliente">
            <b-form-group slot-scope="{ valid, errors }" label="Nota Cliente( max: 255)">
              <b-form-group>
                <b-form-textarea
                  v-model="booking.clientnote"
                  :state="errors[0] ? false : valid ? true : null"
                  placeholder="Escribe la nota"
                  :maxlength="max"
                  rows="1"
                  @input="setDataInBooking(booking)"
                >
                </b-form-textarea>
                <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
              </b-form-group>
              <div class="text-center">
                <b-badge
                  variant="success"
                  style="width: 60%; cursor: not-allowed"
                >
                  Le quedan {{ max - booking.clientnote.length }} caracteres
                </b-badge
                >
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col v-if="booking.categoryName == 'ReservasOwners'" >
          <ValidationProvider rules="max:255" name="Notas Prearrival">
            <b-form-group
              slot-scope="{ valid, errors }"
              label=""
            >
              <b-form-group>
                <b-form-textarea
                  v-model="booking.note"
                  :state="errors[0] ? false : valid ? true : null"
                  placeholder="Escribe la nota"
                  :maxlength="max"
                  rows="1"
                  @input="setDataInBooking(booking)"
                >
                </b-form-textarea>
                <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
              </b-form-group>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </b-card>

    <b-form-checkbox
      v-if="booking.categoryName != 'ReservasOwners'"
      class="mt-2 mb-2 float-right"
      v-model="booking.isMain"
      name="checkbox-1"
      value="true"
      :disabled="!booking.isMain && existBookingMain"
      unchecked-value="false"
      @change="setMain(booking, $event)"
    >
      Usar datos cliente principal
    </b-form-checkbox>
  </b-tab>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { namesPrefix } from '@/data/data'
import { occupanttype } from '@/data/fivesClub'
import BookingGuests from "@/modules/shop/components/orderSummary/BookingGuests"
import BookingGuestsOwners from "@/modules/shop/components/orderSummary/BookingGuestsOwners"

export default {
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  components: {
    BookingGuests,
    BookingGuestsOwners,
  },
  data() {
    return {
      max: 255,
      namesPrefix,
      daGuest: '',
      guests: [],
      selectedGuest: [],
      childages: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]
    };
  },
  computed: {
    ...mapState("shop", ["cart"]),
		...mapState('bookings',['searchForms']),
    existBookingMain() {
      return this.cart.items.find((item) => item.isMain === true);
    },
    userFilled(){
      if (this.selectedGuest.mainGuest) return 'UserCheckIcon'
      if (
        this.selectedGuest.namesPrefix === '' ||
        this.selectedGuest.GivenName === '' ||
        this.selectedGuest.Surname === '' ||
        this.selectedGuest.PhoneNumber === '' ||
        this.selectedGuest.Email === ''
      ) return 'UserPlusIcon'
      else return 'UserIcon'
    },
    showOccupantType(){
      return occupanttype.find(type => type.id == this.booking.occupanttype)?.name
    }
  },
  methods: {
    ...mapMutations("shop", [
      "setDataBookingItem",
      "setMainBooking",
      "copyDataMainBooking",
    ]),
    setDataInBooking(data) {
      this.setDataBookingItem(data);
    },
    setDataInGuests() {
      this.setDataInBooking(this.booking)
    },
    setMain(booking, e) {
      const { uuid } = booking;
      const bool = e == "true" ? true : false;
      const payload = { key: uuid, bool };
      this.setMainBooking(payload);
    },
    copyBooking() {
      const { uuid } = this.booking;
      this.copyDataMainBooking(uuid);
    },
    selectGuest(id){
      const select = this.booking.guests.find((item) => item.id === id)
      this.selectedGuest = select
    },
    cardTitle(guest){
      let number = 1
      if (guest.mainGuest) return `Main Guest: ${guest.GivenName} ${guest.Surname}`
      else return guest.Adult ? `Adult ${number++}: ${guest.GivenName} ${guest.Surname}` : `Child ${number++}: ${guest.GivenName} ${guest.Surname}`
    }
  },
};
</script>


<style scoped>
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #177bd2;
  background-color: #267dc7 !important;
}

label.custom-control-label {
  font-weight: 600;
}

.margin-half{
  margin: 0.5rem;
}
</style>